import Mock from "../mock";

const database = {
  information: {
    name: "Sunny Dargahi",
    aboutContent:
      "I am a frontend web developer. I can provide clean code and pixel perfect design. I also make website more & more interactive with web animations.",
    
    phone: "949 516 1429",
    
    email: "",
    address: "Brea CA 92821",
    freelanceStatus: "Available",
    socialLinks: {
      
      pinterest: "",
      behance: "",
      linkedin: "https://www.linkedin.com/in/sunny-dargahi-1a4a174b/",
      dribbble: "",
      github: "https://github.com/Sanazsunny",
    },
    brandImage: "/images/sunnydargahi1.jpg",
    aboutImage: "/images/sunnydargahi.jpg",
    aboutImageLg: "/images/sunnydargahi.jpg",
    cvfile: "/files/SunnyDargahi-WEB.pdf",
  },
  services: [
    {
      title: "Web Design",
      icon: "brush-alt",
      details:
        "Web Design",
    },
    {
      title: "Web Development",
      icon: "code",
      details:
        "Web Development",
    },
    {
      title: "Mobile Application",
      icon: "mobile",
      details:
        "Mobile Application",
    },
  ],
 reviews: [
    {
      id: 1,
      content:"I love my website",
      author: {
        name: "shally",
        designation: "Client",
      },
    },
    {
      id: 2,
      content:"Very Creative and functional website",
      author: {
        name: "Alex",
        designation: "Client",
      },
    },
    {
      id: 3,
      content: "",
      author: {
        name: "Irving Feeney",
        designation: "Tessa",
      },
    },
  ],
  skills: [
    {
      title: "HTML5",
      value: 95,
    },
    {
      title: "CSS3",
      value: 90,
    },
    {
      title: "Javascript",
      value: 70,
    },
    {
      title: "jQuery",
      value: 85,
    },
    {
      title: "ReactJS",
      value: 80,
    },
    {
      title: "Photoshop",
      value: 80,
    },
  ],
  portfolios: [
    {
      id: 1,
      title: "Urecon.com",
      
      imageUrl: "/images/urecon.jpg",
      largeImageUrl: ["/images/urecon.jpg"],
      url: "https://urecon.com/",
    },
    {
      id: 2,
      title: "gfcp.com",
    
      imageUrl: "/images/gfcp.jpg",
      largeImageUrl: [
        "/images/gfcp.jpg",
        "/images/gfcp.jpg",
      ],
      url: "https://www.gfps.com/us/en-us/products-solutions/brands/central-plastics.html?utm_source=oldvanity&utm_medium=redirect&utm_campaign=oldvanity",
    },
    {
      id: 3,
      title: "Calspas.com",
      
      imageUrl: "/images/calspas.jpg",
      url: "https://calspas.com/",
    },
    {
      id: 4,
      title: "Calflamebbq.com",
     
      imageUrl: "/images/calflame.jpg",
      largeImageUrl: ["/images/calflame.jpg"],
      url: "https://calflamebbq.com/",
    },
    {
      id: 5,
      title: "American-spa.com",
      
      imageUrl: "/images/americanspa.jpg",
      largeImageUrl: ["/images/americanspa.jpg"],
      url:"https://american-spa.com/",
    },
    {
      id: 6,
      title: "Quickspa.com",
      
      imageUrl: "/images/quickspa.jpg",
      largeImageUrl: ["/images/quickspa.jpg"],
      url:"https://quickspaparts.com/",
    },
    {
      id: 7,
      title: "Quickbbq.com",
     
      imageUrl: "/images/quickbbq.jpg",
      largeImageUrl: ["/images/quickbbq.jpg"],
      url: "https://www.quickbbqparts.com/",
    },
  
      {id: 8,
      title: "Allnaturalcbds",
     
      imageUrl: "/images/allnatural.jpg",
      largeImageUrl: ["/images/allnatural.jpg"],
      url:"https://allnaturalcbds.com/en/"
    },
    /*{
      id: 9,
      title: "Packet",
      subtitle: "Beautiful packet & product design.",
      imageUrl: "/images/portfolio-image-9.jpg",
      largeImageUrl: ["/images/portfolio-image-9-lg.jpg"],
    },
    {
      id: 10,
      title: "Pen Holder",
      subtitle: "A pen holder with beautiful design.",
      imageUrl: "/images/portfolio-image-3.jpg",
      largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
      url: "#",
    },
    {
      id: 11,
      title: "Coffee Mug",
      subtitle: "Awesome coffee mug design.",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg",
        "/images/portfolio-image-4-lg2.jpg",
      ],
      url: "https://facebook.com",
    },
    {
      id: 12,
      title: "Tea & Coffee Mug",
      subtitle: "Beautiful mug with logo.",
      imageUrl: "/images/portfolio-image-2.jpg",
      url: "https://pinterest.com",
    },
    {
      id: 13,
      title: "T-shirt Mockup",
      subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: "https://dribbble.com",
    },
    {
      id: 14,
      title: "Mug",
      subtitle: "Mug with awesome style",
      imageUrl: "/images/portfolio-image-5.jpg",
      largeImageUrl: ["/images/portfolio-image-5-lg.jpg"],
    },
    {
      id: 15,
      title: "Pendrive",
      subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/portfolio-image-6.jpg",
      largeImageUrl: ["/images/portfolio-image-6-lg.jpg"],
    },
    {
      id: 16,
      title: "Beautiful Pendrive",
      subtitle: "Pendrive with great design & flexible.",
      imageUrl: "/images/portfolio-image-7.jpg",
      largeImageUrl: ["/images/portfolio-image-7-lg.jpg"],
      url: "https://twitter.com",
    },
    {
      id: 17,
      title: "Sticker",
      subtitle: "Clip sticker mockup design.",
      imageUrl: "/images/portfolio-image-8.jpg",
      largeImageUrl: ["/images/portfolio-image-8-lg.jpg"],
    },
    {
      id: 18,
      title: "Packet",
      subtitle: "Beautiful packet & product design.",
      imageUrl: "/images/portfolio-image-9.jpg",
      largeImageUrl: ["/images/portfolio-image-9-lg.jpg"],
    },
    {
      id: 19,
      title: "T-shirt Mockup",
      subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: "https://dribbble.com",
    },
    {
      id: 20,
      title: "Coffee Mug",
      subtitle: "Awesome coffee mug design.",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg",
        "/images/portfolio-image-4-lg2.jpg",
      ],
      url: "https://facebook.com",
    },
    {
      id: 21,
      title: "Tea & Coffee Mug",
      subtitle: "Beautiful mug with logo.",
      imageUrl: "/images/portfolio-image-2.jpg",
      url: "https://pinterest.com",
    },
    {
      id: 22,
      title: "Pen Holder",
      subtitle: "A pen holder with beautiful design.",
      imageUrl: "/images/portfolio-image-3.jpg",
      largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
      url: "#",
    },
    {
      id: 23,
      title: "Mug",
      subtitle: "Mug with awesome style",
      imageUrl: "/images/portfolio-image-5.jpg",
      largeImageUrl: ["/images/portfolio-image-5-lg.jpg"],
    },*/
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "Aug 2022 – Apr 2024",
        position: "Web Application Developer",
        company: "GF Piping Systems",
        details:
                "* Developed front-end for internal Keys application using React with Hooks *Configured and implemented robust content security policies (CSP) and headers to protect web applications. * Redesigned and developed responsive company websites using Figma, Photoshop, PHP, CSS3, Bootstrap, JavaScript, jQuery, HTML5, adhering to ADA, WCAG, and CSP guidelines. * Created interactive dashboards and reports in Power BI to track project KPIs and metrics,improving stakeholder visibility. * Led the development of the GF Central Plastics website, collaborating closely with the GFCP team for seamless user experience (www.gfcp.com). * Tested applications using Jest and Enzyme; practical implementation of SEO principles. * Designed and developed a new microsite in AEM for GF Central Plastics. * Administered, configured, and monitored enterprise systems, and developed quality technical documentation for GF applications and websites."

 

      },
      {
        id: 2,
        year: "Nov 2017-Dec 2021",
        position: "Web Developer",
        company: "Cal Spas",
        details:
"* Managed, redesigned and developed company websites: calspas.com, calflamebbq.com, quickspaparts.com, and others. * Set up and managed SharePoint for company needs. * Customized dealer websites using ASP.NET and C# templates. * Improved SEO rankings and site traffic using Google Search Console and SEMrush * Designed branded banners, website logos, and animated motion graphics * Developed WordPress and Shopify websites for Cal Spas subsidiaries. * Managed email campaigns using Mobile Storm and Eloqua."
 
     },
      {
        id: 3,
        year: "Jan 2014-Nov 2017",
        position: "Programmer/ Analyst",
        company: "Freelance",
        details:
"* Designed and developed web application for hospitals and medical clinics including custom EHR solutions * Collaborated with business owners to meet business requirements * Increased site traffic and SEO rankings using Google Search Console."      },
    ],
    educationExperience: [
      {
        id: 1,
      
        graduation: "Web development Certificates ",
        university: "Fullerton College",
       
      },
      {
        id: 2,
      
        graduation: "Bachelor of Science",
        university: "University of Science and Culture - Tehran, Iran",
     
      },
     
    ],
  },
  blogs: [
    {
      id: 1,
      title: "Markdown & Html supported blog.",
      featuredImage: "/images/blog-image-1.jpg",
      filesource: "../../blog/markdown-html-supported-blog.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 2,
      title: "Installing NodeJS on your device.",
      featuredImage: "/images/blog-image-2.jpg",
      filesource: "../../blog/installing-nodejs-on-your-device.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 3,
      title: "UI/UX design starter with Adobe XD.",
      featuredImage: "/images/blog-image-3.jpg",
      filesource: "../../blog/uiux-design-starter-with-adobe-xd.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 4,
      title: "Boost your post for increasing sales.",
      featuredImage: "/images/blog-image-4.jpg",
      filesource: "../../blog/boost-your-post-for-increasing-sales.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 5,
      title: "Difference between GatsbyJS & NextJS.",
      featuredImage: "/images/blog-image-5.jpg",
      filesource: "../../blog/difference-between-gatsbyjs-and-nextjs.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 6,
      title: "How to choose javascript framework for project.",
      featuredImage: "/images/blog-image-6.jpg",
      filesource:
        "../../blog/how-to-choose-javascript-framework-for-project.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 7,
      title: "Web automation with python language.",
      featuredImage: "/images/blog-image-7.jpg",
      filesource: "../../blog/web-automation-with-python-language.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 8,
      title: "Time to use latest technology for creating a website.",
      featuredImage: "/images/blog-image-8.jpg",
      filesource:
        "../../blog/time-to-use-latest-technology-for-creating-a-website.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 9,
      title: "Think out of the box.",
      featuredImage: "/images/blog-image-9.jpg",
      filesource: "../../blog/think-out-of-the-box.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 10,
      title: "Trending designs in 2020.",
      featuredImage: "/images/blog-image-1.jpg",
      filesource: "../../blog/trending-designs-in-2020.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 11,
      title: "How to get 10k instagram followers?",
      featuredImage: "/images/blog-image-2.jpg",
      filesource: "../../blog/how-to-get-10k-instagram-followers.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 12,
      title: "What NodeJS can do?",
      featuredImage: "/images/blog-image-3.jpg",
      filesource: "../../blog/what-nodejs-can-do.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 13,
      title: "Futures of javascript.",
      featuredImage: "/images/blog-image-4.jpg",
      filesource: "../../blog/future-of-javascript.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 14,
      title: "Popular javascript library in 2020.",
      featuredImage: "/images/blog-image-5.jpg",
      filesource: "../../blog/popular-javascript-library-in-2020.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 15,
      title: "Promrammers must read books.",
      featuredImage: "/images/blog-image-6.jpg",
      filesource: "../../blog/programmers-must-read-books.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
  ],
  contactInfo: {
    phoneNumbers: ["949 516 1429"],
    emailAddress: ["sunny.dargahi@gmail.com"],
    address: "Brea CA 92821",
  },
};

Mock.onGet("/api/information").reply((config) => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply((config) => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply((config) => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply((config) => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply((config) => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply((config) => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply((config) => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply((config) => {
  const response = database.contactInfo;
  return [200, response];
});
